<template>
    <div class="embedPage">
      <div v-if="user" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <social-review-generator v-if="user.agency.id"
                                     :agency-id="user.agency.id"
                                     :userId="user.id"
                                     :starting-step="step"
                                     :header-text="user.agency.settings.insta_stream_embed_form_settings.title"
                                     :background-color="user.agency.settings.insta_stream_embed_form_settings.background_color"
                                     :main-text-color="user.agency.settings.insta_stream_embed_form_settings.main_text_color"
                                     :primary-button-background-color="user.agency.settings.insta_stream_embed_form_settings.primary_button_background_color"
                                     :primary-button-text-color="user.agency.settings.insta_stream_embed_form_settings.primary_button_text_color"
                                     :secondary-button-background-color="user.agency.settings.insta_stream_embed_form_settings.secondary_button_background_color"
                                     :secondary-button-text-color="user.agency.settings.insta_stream_embed_form_settings.secondary_button_text_color"
                                     :copy-area-background-color="user.agency.settings.insta_stream_embed_form_settings.copy_area_background_color"
                                     :copy-area-text-color="user.agency.settings.insta_stream_embed_form_settings.copy_area_text_color"
                                     :copy-button-background-color="user.agency.settings.insta_stream_embed_form_settings.copy_button_background_color"
                                     :copy-button-text-color="user.agency.settings.insta_stream_embed_form_settings.copy_button_text_color"
                                     :qr-text="user.agency.settings.insta_stream_embed_form_settings.qr_text"
                                     :returnType="type"
              ></social-review-generator>
              <ValidationObserver
                  v-slot="{ handleSubmit, invalid, touched }"
                  ref="embedForm"
              >
                <form v-if="user.agency.id" class="settings-form" @submit.prevent="handleSubmit(updateEmbedForm)">
                  <label>Change Form Step</label>
                  <select v-model="step" class="form-control mb-2">
                    <option :value="1">Step 1</option>
                    <option :value="2">Step 2</option>
                    <option :value="3">Step 3</option>
                  </select>
                  <TextInput v-if="step === 1" v-model="user.agency.settings.insta_stream_embed_form_settings.title" label="Form Title" />
                  <div class="row">
                    <div class="col-md-6 col-lg-3">
                      <label>Main Background Color</label>
                      <input v-model="user.agency.settings.insta_stream_embed_form_settings.background_color" type="color" class="form-control"  />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <label>Main Text Color</label>
                      <input v-model="user.agency.settings.insta_stream_embed_form_settings.main_text_color" type="color" class="form-control"  />
                    </div>
                    <div v-if="step !== 3" class="col-md-6 col-lg-3">
                      <label>Primary Button Background Color</label>
                      <input v-model="user.agency.settings.insta_stream_embed_form_settings.primary_button_background_color" type="color" class="form-control"  />
                    </div>
                    <div v-if="step !== 3" class="col-md-6 col-lg-3">
                      <label>Primary Button Text Color</label>
                      <input v-model="user.agency.settings.insta_stream_embed_form_settings.primary_button_text_color" type="color" class="form-control"  />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col align-self-end text-right mt-md-0">
                      <b-form-group>
                        <b-button
                            variant="primary"
                            class="btn-dark-blue"
                            :disabled="loadingUpdate || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else>Update</span>
                        </b-button>
                      </b-form-group>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
              <label>Copy and Paste this HTML where you want your Form to display <button class="btn btn-dark-blue btn-sm ml-4" @click="copyEmbedCode"><Feather type="copy" style="vertical-align: middle" /> Copy Code </button></label>
              <!-- eslint-disable vue/no-v-html -->
              <textarea cols="30" rows="5" class="form-control" style="white-space: pre-line" v-html="embedHTML">
                </textarea>
              <!--                                     style="pointer-events: none"-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Vue from 'vue'
  import EmbedForm from '../../../embed-form/src/components/Form';
  export default {
    components:{
      'social-review-generator': EmbedForm
    },
    data() {
      return {
        loadingUpdate: false,
        qrReady:false,
        user: {
          agency: {
            whitelabel: {},
            settings:{}
          },
        },
        step:1,
        type: 'instaWidget'
      }
    },
    computed: {
      embedHTML() {
        console.log(this.type)
        return `<!-- Start Instagram Widget Generator -->
  &lt;script src="https://rengine.sfo3.cdn.digitaloceanspaces.com/generator-scripts/v4/widget-generator-loader.js">&lt;/script>
  <leads-widget-generator agency-id="${this.user.agency.id}" return-type="instaWidget"></leads-widget-generator>
  <!-- End Instagram Widget Generator -->
  `;
      }
    },
    mounted() {
      this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
      console.log(this.user);
    },

    methods: {
      copyEmbedCode() {
        navigator.clipboard.writeText(this.embedHTML.replaceAll('&lt;','<'));
        Vue.$toast.open({
          message: 'Code Copied',
          type: 'success',
          duration: 5000,
        })
      },
      updateEmbedForm(){
        this.loadingUpdate = true

        let params = {
          insta_stream_embed_form_settings:this.user.agency.settings.insta_stream_embed_form_settings,
          setting_form:'insta_stream_embed_form'
        }

        this.$store
            .dispatch('agency/editSettings', params)
            .then(() => {
              this.loadingUpdate = false
            })
            .catch((err) => {
              if (err.response.data.errors) {
                this.$refs.embedForm.setErrors(err.response.data.errors)
              }
              this.loadingUpdate = false
            })
      },
    },
  }
  </script>
  <style lang="scss">

  </style>
